import ApolloClient from '@/apollo'
import {
  GET_AVAILABLE_SMART_GROUPS,
  GET_AVAILABLE_STANDARD_GROUPS,
  GET_GROUP_BY_ID, GET_GROUP_PLAYLISTS_BY_ID, GET_GROUP_SCHEDULES,
  GET_GROUP_SMART_SCHEDULES_BY_ID,
  GET_GROUP_STANDARD_SCHEDULES_BY_ID_WITH_PARENTS
} from '@/graphql/queries'
import {
  CREATE_PLAYLIST,
  CREATE_SMART_GROUP,
  CREATE_STANDARD_GROUP,
  DELETE_PLAYLIST_BY_ID,
  DELETE_SMART_GROUP_BY_ID,
  DELETE_STANDARD_GROUP_BY_ID,
  UPDATE_SCHEDULE_BY_ID,
  UPDATE_SMART_GROUP_BY_ID,
  UPDATE_SMART_GROUP_BY_ID_EXTENDED,
  UPDATE_STANDARD_GROUP_BY_ID,
  UPDATE_STANDARD_GROUP_BY_ID_EXTENDED
} from '@/graphql/mutations'
import router from '@/router'
import { updateEntityById } from '@/utils'
import { SMART_GROUP_TYPE, STANDARD_GROUP_TYPE } from '@/constants'
import { copyText } from 'vue3-clipboard'
import { handleError } from '@/helpers/ErrorHandler'

const JamfConfigTemplate = `
      <dict>
      <key>secret</key>
      <string>%TOKEN%</string>
      <key>externalDeviceId</key>
      <string>$UDID</string>
      <key>deviceName</key>
      <string>$DEVICENAME</string>
      <key>groupId</key>
      <string>%GROUP_ID%</string>
      </dict>
    `

export default {
  namespaced: true,
  state: {
    availableStandardGroups: null,
    availableStandardGroupsLoading: false,
    availableSmartGroups: null,
    availableSmartGroupsLoading: false,
    currentGroup: null,
    currentGroupIsLoading: false,
    currentGroupSchedules: [],
    currentGroupDevices: [],
    currentGroupPlaylists: [],
    currentGroupForcedPlaylist: null,
    currentGroupRootGroup: null,
    playlistsFetching: true
  },
  actions: {

    async getAvailableStandardGroups ({ commit }) {
      commit('SET_AVAILABLE_STANDARD_GROUPS_LOADING_STATUS', true)
      try {
        const { data: { listAvailableStandardGroups } } = await ApolloClient.query({ query: GET_AVAILABLE_STANDARD_GROUPS, fetchPolicy: 'no-cache' })
        commit('SET_AVAILABLE_STANDARD_GROUPS', listAvailableStandardGroups)
      } catch (e) {
        commit('SET_AVAILABLE_STANDARD_GROUPS_LOADING_STATUS', false)
        handleError(e)
      }
    },
    async getAvailableSmartGroups ({ commit }, setEmpty) {
      if (setEmpty) {
        return commit('SET_AVAILABLE_SMART_GROUPS', [])
      }
      commit('SET_AVAILABLE_SMART_GROUPS_LOADING_STATUS', true)
      try {
        const { data: { listAvailableSmartGroups } } = await ApolloClient.query({ query: GET_AVAILABLE_SMART_GROUPS, fetchPolicy: 'no-cache' })
        commit('SET_AVAILABLE_SMART_GROUPS', listAvailableSmartGroups)
      } catch (e) {
        commit('SET_AVAILABLE_SMART_GROUPS_LOADING_STATUS', false)
        handleError(e)
      }
    },
    async createGroup ({ commit }, { input, type }) {
      const loadingAction = type === STANDARD_GROUP_TYPE ? 'SET_AVAILABLE_STANDARD_GROUPS_LOADING_STATUS' : 'SET_AVAILABLE_SMART_GROUPS_LOADING_STATUS'
      const updateAction = type === STANDARD_GROUP_TYPE ? 'ADD_AVAILABLE_STANDARD_GROUP' : 'ADD_AVAILABLE_SMART_GROUP'
      if (type !== STANDARD_GROUP_TYPE) {
        delete input.language
      }
      const mutation = type === STANDARD_GROUP_TYPE ? CREATE_STANDARD_GROUP : CREATE_SMART_GROUP
      commit(loadingAction, true)
      const groupObjString = type === STANDARD_GROUP_TYPE ? 'createStandardGroup' : 'createSmartGroup'
      try {
        const { data } = await ApolloClient.mutate({ mutation, variables: { input } })
        commit(updateAction, data?.[groupObjString])
        return data?.[groupObjString]
      } catch (e) {
        commit(loadingAction, false)
        handleError(e)

      }
    },
    async updateGroup ({ commit, getters }, { id, input, type }) {
      const isCurrentGroup = getters.currentGroupId === id
      const loadingAction = type === STANDARD_GROUP_TYPE ? 'SET_AVAILABLE_STANDARD_GROUPS_LOADING_STATUS' : 'SET_AVAILABLE_SMART_GROUPS_LOADING_STATUS'
      commit(loadingAction, true)
      const mutation = type === STANDARD_GROUP_TYPE
        ? isCurrentGroup
          ? UPDATE_STANDARD_GROUP_BY_ID_EXTENDED
          : UPDATE_STANDARD_GROUP_BY_ID
        : isCurrentGroup
          ? UPDATE_SMART_GROUP_BY_ID_EXTENDED
          : UPDATE_SMART_GROUP_BY_ID
      const updateGroupAction = type === STANDARD_GROUP_TYPE ? 'UPDATE_AVAILABLE_STANDARD_GROUP' : 'UPDATE_AVAILABLE_SMART_GROUP'
      const groupObjString = type === STANDARD_GROUP_TYPE ? 'updateStandardGroupById' : 'updateSmartGroupById'
      if (type !== STANDARD_GROUP_TYPE) {
        delete input.language
      }

      try {
        const { data } = await ApolloClient.mutate({ mutation, variables: {id, input} })
        commit(updateGroupAction, data?.[groupObjString])
        if (isCurrentGroup) {
          commit('UPDATE_CURRENT_GROUP', data?.[groupObjString])
        }
        return data?.[groupObjString]
      } catch (e) {
        commit(loadingAction, false)
        handleError(e)
      }
    },
    async updateCurrentGroup ({ dispatch, getters }, { input }) {
      const id = getters.currentGroupId
      const type = getters.currentGroupType
      await dispatch('updateGroup', { id, input, type })
    },
    async deleteGroup ({ commit, dispatch }, { id, type }) {
      const loadingAction = type === STANDARD_GROUP_TYPE ? 'SET_AVAILABLE_STANDARD_GROUPS_LOADING_STATUS' : 'SET_AVAILABLE_SMART_GROUPS_LOADING_STATUS'
      const mutation = type === STANDARD_GROUP_TYPE ? DELETE_STANDARD_GROUP_BY_ID : DELETE_SMART_GROUP_BY_ID
      try {
        await ApolloClient.mutate({ mutation, variables: { id } })
        switch (type) {
          case STANDARD_GROUP_TYPE:
            await dispatch('getAvailableStandardGroups')
            break
          case SMART_GROUP_TYPE:
            await dispatch('getAvailableSmartGroups')
        }
      } catch (e) {
        commit(loadingAction, false)
        handleError(e)
      }
    },
    async setCurrentGroup ({ commit, getters, dispatch, state }, { groupId }) {
      if (!groupId || groupId === getters.currentGroupId) {
        return
      }
      commit('SET_CURRENT_GROUP_LOADING_STATUS', true)
      state.playlistsFetching = true
      try {
        const { data: { getGroupById: group } } = await ApolloClient.query({ query: GET_GROUP_BY_ID, variables: { id: groupId }, fetchPolicy: 'no-cache' })
        await commit('SET_CURRENT_GROUP', group)
        await dispatch('fetchCurrentGroupPlaylists')
        await dispatch('setCurrentGroupRootGroup')
        commit('SET_CURRENT_GROUP_FORCED_PLAYLIST', group.schedule?.recursiveForcedPlaylist)
      } catch (e) {
        if (e.graphQLErrors?.[0]?.extensions?.code === 'FORBIDDEN') {
          return router.push({ name: 'Home' })
        }
        handleError(e)
      }
    },
    async fetchCurrentGroupPlaylists ({ commit, getters, state }) {
      const groupId = getters.currentGroupId
      if (!groupId) return
      state.playlistsFetching = true
      try {
        const { data: { getGroupById: { playlists } } } = await ApolloClient.query({ query: GET_GROUP_PLAYLISTS_BY_ID, variables: { id: groupId }, fetchPolicy: 'no-cache' })
        await commit('SET_CURRENT_GROUP_PLAYLISTS', playlists)
      } catch (e) {
        if (e.graphQLErrors?.[0]?.extensions?.code === 'FORBIDDEN') {
          return router.push({ name: 'Home' })
        }
        handleError(e)
      }
    },
    setCurrentGroupRootGroup ({commit, getters}) {
      let groupId = getters.currentGroupId
      const findRootGroup = (groupId, groups) => {
        const group = groups.find(g => g.id === groupId)
        if (!group) return null
        if (!group.parentGroupId) return group
        return findRootGroup(group.parentGroupId, groups)
      };
      commit('SET_CURRENT_GROUP_ROOT_GROUP', findRootGroup(groupId, getters.availableStandardGroups))
    },
    async createCurrentGroupPlaylist ({ getters, commit, state }, payload) {
      state.playlistsFetching = true
      const groupId = getters.currentGroupId
      try {
        const { data: { createPlaylist } } = await ApolloClient.mutate({ mutation: CREATE_PLAYLIST, variables: { input: { ...payload, groupId } }, fetchPolicy: 'no-cache' })
        commit('ADD_CURRENT_GROUP_PLAYLIST', createPlaylist)
        commit('ADD_PLAYLIST_TO_AVAILABLE_GROUPS', { playlist: createPlaylist, groupId })
        return createPlaylist
      } catch (e) {
        handleError(e)
      }
    },
    async deleteCurrentGroupPlaylist ({ getters, commit, state }, payload) {
      const { id } = payload
      state.playlistsFetching = true
      try {
        await ApolloClient.mutate({ mutation: DELETE_PLAYLIST_BY_ID, variables: payload, fetchPolicy: 'no-cache' })
        commit('REMOVE_CURRENT_GROUP_PLAYLIST', id)
        if (id === getters.currentGroupForcedPlaylistId) {
          commit('REMOVE_CURRENT_GROUP_FORCED_PLAYLIST')
        }
      } catch (e) {
        handleError(e)
      }
    },
    async getCurrentGroupSchedules ({ getters, commit }) {
      const groupId = getters.currentGroupId
      if (!groupId) return
      try {
        const { data: { getHierarchySchedulesByGroupId }  } = await ApolloClient.query({ query: GET_GROUP_SCHEDULES, variables: { groupId: groupId }, fetchPolicy: 'no-cache' })
        commit('SET_CURRENT_GROUP_SCHEDULES', getHierarchySchedulesByGroupId)
      } catch (e) {
        handleError(e)
      }
    },
    async updateCurrentGroupSchedule ({ commit, getters }, input) {
      try {
        const { data: { updateScheduleById } } = await ApolloClient.mutate({ mutation: UPDATE_SCHEDULE_BY_ID, variables: input })
        commit('UPDATE_CURRENT_GROUP_SCHEDULE', updateScheduleById)
        commit('SET_CURRENT_GROUP_FORCED_PLAYLIST', updateScheduleById?.data?.forcedPlaylist)
      } catch (e) {
        handleError(e)
      }
    },
    async updateGroupScheduleByGroupId ({ commit, getters }, { groupId, input }) {
      try {
        const { data: { getGroupById: { schedule: { id: scheduleId } } } } = await ApolloClient.query({ query: GET_GROUP_BY_ID, variables: { id: groupId }, fetchPolicy: 'no-cache' })
        const { data: { updateScheduleById } } = await ApolloClient.mutate({ mutation: UPDATE_SCHEDULE_BY_ID, variables: { id: scheduleId, input }})
        commit('UPDATE_CURRENT_GROUP_SCHEDULE', updateScheduleById)
        commit('SET_CURRENT_GROUP_FORCED_PLAYLIST', updateScheduleById?.data?.forcedPlaylist)
      } catch (e) {
        handleError(e)
      }
    },
    copyGroupJAMFSettingsToClipboard ({getters, rootGetters}, {groupId, cb}) {
      const token = rootGetters['workspace/deviceInitializationToken']
      groupId = groupId || getters.currentGroupId
      if (!token || !groupId) return
      copyText(JamfConfigTemplate.replace('%TOKEN%', token).replace('%GROUP_ID%', groupId), undefined, (error, event) => {
        if (error) {
        } else {
          cb && cb()
        }
      })
    }
  },
  getters: {
    availableGroups: state => [...state.availableStandardGroups, ...state.availableSmartGroups],
    availableStandardGroups: state => state.availableStandardGroups,
    availableSmartGroups: state => state.availableSmartGroups,
    availableStandardGroupsIdMap: state => {
      return (state.availableStandardGroups || []).reduce((acc, g) => {
        acc[g.id] = g.name
        return acc
      }, {})
    },
    availableStandardGroupsPlaylistsIdMap: state => {
      return (state.availableStandardGroups || []).reduce((acc, g) => {
        const plIdMap = g.playlists?.reduce((acc, pl) => {
          acc[pl.id] = pl.name
          return acc
        }, {})
        acc = { ...acc, ...plIdMap }
        return acc
      }, {})
    },
    availableSmartGroupsIdMap: state => {
      return (state.availableSmartGroups || []).reduce((acc, g) => {
        acc[g.id] = g.name
        return acc
      }, {})
    },
    availableSmartGroupsPlaylistsIdMap: state => {
      return (state.availableSmartGroups || []).reduce((acc, g) => {
        const plIdMap = g.playlists?.reduce((acc, pl) => {
          acc[pl.id] = pl.name
          return acc
        }, {})
        acc = { ...acc, ...plIdMap }
        return acc
      }, {})
    },
    availableStandardGroupsTree: state => ({ groupIdToExclude, excludeBranch, playlistIdToExclude, nonSelectableParentGroupId, includeRoot, setDepth, maxGroupsDepth, selectablePlaylists, checkable } = {}) => {
      if (!state.availableStandardGroups) return []
      const availableGroups = state.availableStandardGroups
      selectablePlaylists = selectablePlaylists || !!playlistIdToExclude
      const groups = availableGroups.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)).map(({ id, name, parentGroupId, playlists, language, createdAt }, index) => {
        playlists = playlists.filter(p => p.id !== playlistIdToExclude)
        return {
          key: id,
          title: name,
          parentGroupId,
          createdAt,
          playlists,
          language,
          groupId: id,
          isGroup: true,
          value: id,
          selectable: !checkable && groupIdToExclude !== id && nonSelectableParentGroupId !== id,
          checkable: checkable || !selectablePlaylists,
          children: (!selectablePlaylists || excludeBranch)
            ? []
            : [...playlists.map(({ id: playlistId, name }) => {
              return {
                key: playlistId,
                title: name,
                parentGroupId: id,
                children: [],
                value: playlistId,
                checkable: true,
                isPlaylist: true
              }
            })]
        }
      })
      const map = {}
      let node
      let nodeToExclude
      const roots = includeRoot
        ? [{
          key: null,
          title: 'Root',
          value: 'ROOT',
          parentGroupId: null,
          children: []
        }]
        : []

      for (let i = 0; i < groups.length; i += 1) {
        map[groups[i].key] = i
        if (groupIdToExclude && excludeBranch && groupIdToExclude === groups[i].key) {
          nodeToExclude = groups[i]
        }
      }

      for (let i = 0; i < groups.length; i += 1) {
        node = groups[i]
        if (node.parentGroupId) {
          groups[map[node.parentGroupId]]?.children.push(node)
        } else {
          roots.push(node)
        }
      }

      if (roots.length === 0) {
        for (let i = 0; i < groups.length; i += 1) {
          node = groups[i]
          if (!map[node.parentGroupId]) {
            roots.push(node)
          }
        }
      }

      if (excludeBranch && nodeToExclude) {
        setFieldForAllChildren({
          node: nodeToExclude,
          field: 'selectable',
          value: false
        })
      }

      (setDepth || maxGroupsDepth) && setGroupsDepth(roots, maxGroupsDepth)
      selectablePlaylists && removeGroupsNodesWithNoChildren(roots)
      if (roots[0]) {
        roots[0].first = true
      }
      return roots
    },
    availableSmartGroupsTree: state => ({ groupIdToExclude, playlistIdToExclude, selectablePlaylists, checkable } = {}) => {
      if (!state.availableSmartGroups) return []
      const availableGroups = state.availableSmartGroups
      selectablePlaylists = selectablePlaylists || !!playlistIdToExclude
      const groups = availableGroups.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)).filter(({id}) => id !== groupIdToExclude)?.map(({ id, name, parentGroupId, playlists }) => {
        playlists = playlists.filter(p => p.id !== playlistIdToExclude)
        return {
          key: id,
          title: name,
          playlists,
          groupId: id,
          isGroup: true,
          value: id,
          selectable: !checkable && groupIdToExclude !== id,
          checkable: checkable || !selectablePlaylists,
          children: !selectablePlaylists
            ? []
            : [...playlists.map(({ id: playlistId, name }) => {
              return {
                key: playlistId,
                title: name,
                parentGroupId: id,
                children: [],
                value: playlistId,
                checkable: true,
                isPlaylist: true
              }
            })]
        }
      })

      selectablePlaylists && removeGroupsNodesWithNoChildren(groups)
      return groups
    },
    rootGroup: state => state.currentGroupRootGroup,
    rootGroupMainPlaylist: state => state.currentGroupRootGroup?.playlists?.find(p => p.isMainPlaylist) || null,
    rootGroupId: state => state.currentGroupRootGroup?.id,
    someGroupsLoading: state => state.availableStandardGroupsLoading || state.availableSmartGroupsLoading,
    availableStandardGroupsLoading: state => state.availableStandardGroupsLoading,
    availableStandardGroupsLoaded: state => !!state.availableStandardGroups,
    availableSmartGroupsLoading: state => state.availableSmartGroupsLoading,
    availableSmartGroupsLoaded: state => !!state.availableSmartGroups,
    availableGroupsLoaded: state => !!state.availableSmartGroups && !!state.availableStandardGroups,
    currentGroup: state => state.currentGroup,
    currentGroupLoaded: state => !!state.currentGroup,
    currentGroupName: state => state.currentGroup?.name,
    currentGroupId: state => state.currentGroup?.id,
    currentGroupType: state => state.currentGroup?.type,
    currentGroupTypeIsSmart: state => state.currentGroup?.type === SMART_GROUP_TYPE,
    currentGroupPlaylists: state => state.currentGroupPlaylists,
    currentGroupPlaylistsScheduled: state => state.currentGroupPlaylists?.filter(p=>p.isScheduled),
    currentGroupPlaylistsUnscheduled: state => state.currentGroupPlaylists?.filter(p=>!p.isScheduled),
    currentGroupMainPlaylist: state => state.currentGroupPlaylists?.find(p => p.isMainPlaylist) || null,
    currentGroupPlaylistsMovable: state => state.currentGroupPlaylists?.length > 1,
    currentGroupDefaultPlaylistId: state => state.currentGroupPlaylists?.[0]?.id,
    currentGroupIsLoading: state => state.currentGroupIsLoading,
    currentGroupDeviceMatchingRule: state => state.currentGroup?.deviceMatchingRule || {},
    currentGroupSchedule: state => state.currentGroupSchedules?.[0],
    currentGroupScheduleId: state => state.currentGroup?.schedule?.id,
    currentGroupParentSchedules: state => state.currentGroupSchedules?.slice(1),
    currentGroupForcedPlaylist: state => {
      return state.currentGroupForcedPlaylist ? {
        ...state.currentGroupForcedPlaylist,
        groupName: state.availableGroups?.find( g => g.id === state.currentGroupForcedPlaylist.groupId)?.name
      } : null
    },
    currentGroupForcedPlaylistId: state => state.currentGroupForcedPlaylist?.id,
    currentGroupHasForcedPlaylist: state => !!state.currentGroupForcedPlaylist,
    playlistsFetching: state => state.playlistsFetching
  },
  mutations: {
    SET_AVAILABLE_STANDARD_GROUPS (state, availableGroups) {
      state.availableStandardGroups = availableGroups
      state.availableStandardGroupsLoading = false
    },
    ADD_AVAILABLE_STANDARD_GROUP (state, availableGroup) {
      state.availableStandardGroups = [...state.availableStandardGroups, availableGroup]
      state.availableStandardGroupsLoading = false
    },
    UPDATE_AVAILABLE_STANDARD_GROUP (state, availableGroup) {
      state.availableStandardGroups = updateEntityById(state, 'availableStandardGroups', availableGroup)
      state.availableStandardGroupsLoading = false
    },
    SET_AVAILABLE_STANDARD_GROUPS_LOADING_STATUS (state, isLoading) {
      state.availableStandardGroupsLoading = isLoading
    },
    SET_AVAILABLE_SMART_GROUPS (state, availableGroups) {
      state.availableSmartGroups = availableGroups
      state.availableSmartGroupsLoading = false
    },
    ADD_AVAILABLE_SMART_GROUP (state, availableGroup) {
      state.availableSmartGroups = [...state.availableSmartGroups, availableGroup]
      state.availableSmartGroupsLoading = false
    },
    UPDATE_AVAILABLE_SMART_GROUP (state, availableGroup) {
      state.availableSmartGroups = updateEntityById(state, 'availableSmartGroups', availableGroup)
      state.availableSmartGroupsLoading = false
    },
    SET_AVAILABLE_SMART_GROUPS_LOADING_STATUS (state, isLoading) {
      state.availableSmartGroupsLoading = isLoading
    },
    SET_CURRENT_GROUP (state, group) {
      state.currentGroup = group
      state.currentGroupIsLoading = false
    },
    SET_CURRENT_GROUP_ROOT_GROUP(state, group) {
      state.currentGroupRootGroup = group
    },
    SET_CURRENT_GROUP_FORCED_PLAYLIST (state, forcedPlaylist) {
      state.currentGroupForcedPlaylist = forcedPlaylist
    },
    REMOVE_CURRENT_GROUP_FORCED_PLAYLIST (state) {
      state.currentGroupForcedPlaylist = null
    },
    UPDATE_CURRENT_GROUP (state, group) {
      state.currentGroup = { ...state.currentGroup, ...group }
      state.currentGroupIsLoading = false
    },
    UPDATE_CURRENT_GROUP_SCHEDULE (state, schedule) {
      state.currentGroupSchedules = [
        schedule,
        ...state.currentGroupSchedules.slice(1)
      ]
    },
    SET_CURRENT_GROUP_SCHEDULES (state, groups) {
      state.currentGroupSchedules = groups
    },
    SET_CURRENT_GROUP_LOADING_STATUS (state, isLoading) {
      state.currentGroupIsLoading = isLoading
    },
    SET_CURRENT_GROUP_PLAYLISTS (state, playlists) {
      if (!state.currentGroup) return
      state.currentGroupPlaylists = playlists
      state.playlistsFetching = false
    },
    UPDATE_CURRENT_GROUP_PLAYLIST (state, playlist) {
      const playlists = updateEntityById(state, 'currentGroupPlaylists', playlist)
      state.currentGroupPlaylists = playlists
      state.playlistsFetching = false
    },
    ADD_CURRENT_GROUP_PLAYLIST (state, playlist) {
      const playlists = [...state.currentGroupPlaylists, playlist]
      state.currentGroupPlaylists = playlists
      state.playlistsFetching = false
    },
    ADD_PLAYLIST_TO_AVAILABLE_GROUPS (state, {
      playlist,
      groupId
    }) {
      const group = state.availableStandardGroups.find(g => g.id === groupId) || state.availableSmartGroups.find(g => g.id === groupId)
      if (!group) return
      group.playlists = [...group.playlists, playlist]
      state.playlistsFetching = false
    },
    REMOVE_CURRENT_GROUP_PLAYLIST (state, playlistId) {
      const playlists = state.currentGroupPlaylists?.filter(pl => pl.id !== playlistId)
      state.currentGroupPlaylists = playlists
      state.playlistsFetching = false
    },
    CLEAR_GROUPS_DATA (state) {
      state.availableStandardGroups = null
      state.availableSmartGroups = null
      state.currentGroup = null
    }
  }
}

function getMaxDepth(tree) {
  if (!tree.children || tree.children.length === 0) {
    return 1
  }
  let maxChildDepth = 0
  for (let child of tree.children) {
    let childDepth = getMaxDepth(child)
    maxChildDepth = Math.max(maxChildDepth, childDepth)
  }
  return 1 + maxChildDepth;
}

const setGroupsDepth = (input, maxDepth, depth = 0) => {
  if (Array.isArray(input) && input.length) {
    input.map(g => {
      g.depth = depth
      g.maxGroupDepth = getMaxDepth(g) - 1
      if (depth + 1 === maxDepth) {
        g.children = []
        return
      }
      g.children && setGroupsDepth(g.children, maxDepth, depth + 1)
    })
  }
}

const removeGroupsNodesWithNoChildren = (tree) => {
  for (let i = tree.length - 1; i >= 0; i--) {
    if (tree[i].isPlaylist) {
      continue
    }
    if (tree[i].children.length === 0) {
      tree.splice(i, 1)
    } else {
      removeGroupsNodesWithNoChildren(tree[i].children)
    }
  }
}

function setFieldForAllChildren({ node, field, value }) {
  if (!node) return
  node[field] = value
  if (node.children && node.children.length > 0) {
    node.children.forEach((node) => setFieldForAllChildren({node, field, value}))
  }
}
